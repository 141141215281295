import React, { useEffect, useRef, forwardRef } from 'react';

import './index.css';

const data = [
  {
    title: 'Frontend',
    skills: [
      { name: 'TypeScript', value: 70 },
      { name: 'JavaScript', value: 85 },
      { name: 'React', value: 80 },
      { name: 'Redux', value: 70 },
      { name: 'HTML', value: 80 },
      { name: 'CSS', value: 75 },
      { name: 'NodeJs', value: 60 },
      { name: 'WebPack', value: 80 },
      { name: 'Flow', value: 65 },
    ],
  },
  {
    title: 'Tech',
    skills: [
      { name: 'Git', value: 70 },
      { name: 'C++', value: 80 },
      { name: 'Qt', value: 80 },
      { name: 'Qml', value: 70 },
      { name: 'PostgreSql', value: 60 },
      { name: 'Jira', value: 50 },
      { name: 'Scrum', value: 55 },
      { name: 'Linux', value: 65 },
      { name: 'Docker', value: 50 },
      { name: 'Kubernates', value: 55 },
    ],
  },
  {
    title: 'Languages',
    skills: [
      { name: 'English', value: 60 },
      { name: 'German', value: 30 },
      { name: 'Russian', value: 95 },
    ],
  },
];

const Item = forwardRef(({ name, value }, ref) => {
  return (
    <div className="skill-element">
      <p className="skill-element-row">
        <span> {name}</span>
        <span> {value}%</span>
      </p>
      <div className="skill-progress">
        <div
          ref={ref}
          style={{ width: `${value}%` }}
          className="skill-active-row"
        >
          <div className="skill-icon-circle">
            <svg width="100%" height="100%">
              <circle cx="50%" cy="50%" r="50%" />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
});

const Block = forwardRef(({ title, skills = [] }, ref) => {
  const itemsRef = useRef([]);

  useEffect(() => {
    // console.log('effect observer');
    if (!itemsRef.current[0]) return;
    // console.log('new observer');
    let observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry, key) => {
          // console.log(entry.isIntersecting, key, entry.boundingClientRect.y);
          if (entry.isIntersecting) entry.target.classList.remove('unvisible');
          else if (entry.boundingClientRect.y > 0)
            entry.target.classList.add('unvisible');
        });
      },
      {
        threshold: 0.5,
      }
    );

    itemsRef.current.forEach((el) => {
      observer.observe(el);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsRef.current]);

  return (
    <div className="skills-block">
      <h5 className="skills-block-title">{title}</h5>
      <ul className="skills-list">
        {skills.map((el, key) => (
          <Item
            key={key}
            ref={(el) => (itemsRef.current[key] = el)}
            name={el.name}
            value={el.value}
          />
        ))}
      </ul>
    </div>
  );
});

export default forwardRef((props, ref) => (
  <section className="skills" ref={ref}>
    <h4 className="skillsTitle">Skills</h4>
    <div className="skillsBlocksList">
      {data.map((el, key) => (
        <Block key={key} title={el.title} skills={el.skills} />
      ))}
    </div>
  </section>
));
