import React, { useEffect, useRef, forwardRef } from 'react';

import './index.css';

// const worksRu = [
//   {
//     position: 'Frontend-developer',
//     company: 'Carprice',
//     dates: 'september 2019 - now',
//     description: `Внедрение нового функционала и доработки проектов на react - redux(в основном онлайн аукцион). Также делал чаты, расширение для chrome, сервер на node js. Подключал к проектам ant-design, flow, eslint, GTM, sentry. Настраивал webpack, оптимизировал приложения.
// Сделал новый адаптивный сайт main.carprice.ru. Сделал один проект на чистом js(без реакта). Работал с code review, merge request, jira и полностью удаленно. Использовал kubernates, docker. Писал немного тесты на jest+enzyme, поддерживал существующие.`,
//   },
//   {
//     position: 'Desktop app developer',
//     company: 'Stc',
//     dates: 'july 2017 - august 2019',
//     description: `Разработка приложений для windows и astra linux.
// Таблицы, фильтры, диалоги, графики, экспорт/импорт в Excel, окно обмена сообщениями, запуск сторонних процессов.
// Работал с бд postgresql, sqllite, interbase.
// Использовал Qt widgets, Qt qml, React Js`,
//   },
//   {
//     position: 'Desktop app developer',
//     company: 'Rti',
//     dates: 'oct 2013 - may 2017',
//     description: `Разработка приложений для windows и astra linux.
// Делал таблицы, диалоги, отображение на карте, отображение состояний оборудования, получение и отправка сетевых сообщений , расчеты траекторий в разных системах координат.
// Работал с sqllite. Использовал Qt widgets.`,
//   },
//   {
//     position: 'Web developer',
//     company: 'Self',
//     dates: 'nov 2012 - sep 2013',
//     description: `Разработка веб-сайтов для ИП или небольших компаний
// Свой сервер Ubuntu-Apache-Nginx-Mysql-Php
// Свой шаблон для сайтов с административной панелью Mysql-Php-Html-Javascript-Css.
// `,
//   },
// ];
// я работаю над фронтоенд логикой нового проекта электронного документо оборота.
// Использую тайпскрипт, реакт, редакс -сага, редакс тулкит , пишу юнит тесты
const works = [
  {
    position: 'Frontend-developer',
    company: 'Transcontainer/Delotech',
    dates: 'december 2021 - now',
    description: `I’ve developed a SPA for the personal cabinet of the logistics specialist from scratch with  Typescript, React, Redux by myself.
     I setted up Webpack 5, compatible with Module Federation.
     I developed almost all UI components, setted up a Storybook analog for them.
     The project has many tables, filters, forms. I’m developing all the front-end part by myself.`,
  },
  {
    position: 'Frontend-developer',
    company: 'Sber Korus',
    dates: 'november 2020 - december 2021',
    description: `I'm working on  the new  electronic document management project.
    I realize complex business logic with Typescript, React, Redux-Saga, unit-testing.
    I work with git, merge requests, codereviews, jira, remotely`,
  },
  {
    position: 'Frontend-developer',
    company: 'Carprice',
    dates: 'september 2019 - oktober 2020',
    description: `Implementation of new functionality and improvement of projects on react - redux (mainly online auction). Also made chats, chrome extension, node js server. Connected to  projects ant-design, flow, eslint, GTM, sentry projects. Configured webpack, optimized applications.
Made a new responsive website main.carprice.ru. I made one project in pure js – 360-spin-view. Worked with code reviews, merge requests, jira and completely remotely. Used kubernates, docker. I wrote a few tests for jest + enzyme, supported the existing ones.
`,
  },
  {
    position: 'Desktop app developer',
    company: 'Stc',
    dates: 'july 2017 - august 2019',
    description: `Application development for windows and astra linux. Tables, filters, dialogs, graphs, export / import to Excel, message exchange window, launching third-party processes. Worked with postgresql, sqllite, interbase databases. Used Qt widgets, Qt qml, React Js`,
  },
  {
    position: 'Desktop app developer',
    company: 'Rti',
    dates: 'oct 2013 - may 2017',
    description: `Application development for windows and astra linux. I made tables, dialogs, display on a map, displaying equipment states, receiving and sending network messages, calculating trajectories in different coordinate systems. Worked with sqllite. Used Qt widgets.
    `,
  },
  {
    position: 'Web developer',
    company: 'Self',
    dates: 'nov 2012 - sep 2013',
    description: ` 
Website development for individual entrepreneurs or small companies Own server Ubuntu-Apache-Nginx-Mysql-Php Own template for sites with an administrative panel Mysql-Php-Html-Javascript-Css.`,
  },
];

const educations = [
  {
    position: 'Mathematic specialist ',
    company: 'Hertzen State Pedagogical University of Russia',
    dates: '2009',
    description: `Faculty of Mathematics, Mathematics, Saint Petersburg`,
  },
  {
    position: 'Physics and Mathematics Lyceum',
    company: 'Saint Petersburg',
    dates: '2004',
  },
];

/*


Application development for windows and astra linux. Tables, filters, dialogs, graphs, export / import to Excel, message exchange window, launching third-party processes. Worked with postgresql, sqllite, interbase databases. Used Qt widgets, Qt qml, React Js

Application development for windows and astra linux. I made tables, dialogs, display on a map, displaying equipment states, receiving and sending network messages, calculating trajectories in different coordinate systems. Worked with sqllite. Used Qt widgets.

Website development for individual entrepreneurs or small companies Own server Ubuntu-Apache-Nginx-Mysql-Php Own template for sites with an administrative panel Mysql-Php-Html-Javascript-Css.

*/

const TimeLineElement = forwardRef(
  ({ position, company, dates, description }, ref) => {
    return (
      <li ref={ref} className="timeline-li  ">
        <div className="icon-circle">
          <svg width="100%" height="100%">
            <circle cx="50%" cy="50%" r="50%" />
          </svg>
        </div>

        <div className="timeline-content">
          <div className="timeline-header">
            <h3 className="timeline-title">{position}</h3>
          </div>
          <p className="timeline-subheading">{company}</p>
          <p className="timeline-subheading">{dates}</p>
          <div className="timeline-li-content">{description}</div>
        </div>
      </li>
    );
  }
);
export const TimeLine = forwardRef(({ title = '', list = [] }, ref) => {
  const itemsRef = useRef([]);
  useEffect(() => {
    if (!itemsRef.current[0]) return;
    let observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry, key) => {
          // console.log(entry.isIntersecting, key, entry.boundingClientRect.y);
          if (entry.isIntersecting) entry.target.classList.add('visible');
          else if (entry.boundingClientRect.y > 0)
            entry.target.classList.remove('visible');
        });
      },
      {
        threshold: 0.5,
      }
    );

    itemsRef.current.forEach((el) => {
      observer.observe(el);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsRef.current]);

  useEffect(() => {
    itemsRef.current = itemsRef.current.slice(0, list.length);
  }, [list.length]);

  return (
    <section className="experience" ref={ref}>
      <h4 className="experienceTitle">{title}</h4>
      <div className="timeLine">
        <ul className="timeLine-ul">
          {list.map((work, key) => (
            <TimeLineElement
              key={key}
              ref={(el) => (itemsRef.current[key] = el)}
              {...work}
            />
          ))}
        </ul>
      </div>
    </section>
  );
});

export const Experience = forwardRef((props, ref) => (
  <TimeLine ref={ref} title="Experience" list={works} />
));
export const Education = forwardRef((props, ref) => (
  <TimeLine ref={ref} title="Education" list={educations} />
));
