import { useRef, useLayoutEffect, useCallback } from 'react';

// const isBrowser = typeof window !== `undefined`

function getScrollPosition(/*{ element, useWindow }*/) {
  //   if (!isBrowser) return { x: 0, y: 0 }

  const target = /*element ? element.current : */ document.body;
  const position = target.getBoundingClientRect();
  //debugger;
  //return useWindow
  //? { x: window.scrollX, y: window.scrollY }
  /*: { x: position.left, y: position.top }*/

  return { x: position.left, y: position.top };
}

export function useScrollPosition(effect, deps, /*element, useWindow,*/ wait) {
  const position = useRef(getScrollPosition(/*{ useWindow }*/));

  let throttleTimeout = useRef(null);

  const callBack = useCallback(() => {
    const currPos = getScrollPosition(/*{ element, useWindow }*/);
    effect({ prevPos: position.current, currPos });
    position.current = currPos;
    throttleTimeout.current = null;
  }, [effect]);

  useLayoutEffect(() => {
    // if (!isBrowser) {
    //   return
    // }

    const handleScroll = () => {
      if (wait) {
        if (throttleTimeout.current === null) {
          throttleTimeout.current = setTimeout(callBack, wait);
        }
      } else {
        callBack();
      }
    };

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
      throttleTimeout.current && clearTimeout(throttleTimeout.current);
    };
  }, [callBack, wait, deps]);
}

useScrollPosition.defaultProps = {
  deps: [],
  // element: false,
  //useWindow: false,
  wait: null,
};
