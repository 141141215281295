import React, { forwardRef } from 'react';
import { ReactComponent as Email } from '../icons/email.svg';
import { ReactComponent as Phone } from '../icons/phone.svg';
import { ReactComponent as Location } from '../icons/location.svg';

import './index.css';

const Item = ({ value, children }) => (
  <div className="contacts-item">
    <div className="contacts-image">{children}</div>
    <div className="contacts-item-text">{value}</div>
  </div>
);

export default forwardRef((props, ref) => (
  <section className="contacts" ref={ref}>
    <h4> Contacts</h4>
    <ul className="contacts-list">
      <Item value="Russia, Saint-Petersburg">
        <Location />
      </Item>
      <Item value="+79117756908">
        <Phone />
      </Item>
      <Item value="aladof@ya.ru">
        <Email />
      </Item>
    </ul>
  </section>
));
