import React, { useRef } from 'react';
import { useSwipeable } from 'react-swipeable';

import styles from './index.module.css';

export default function Dialog({ title, show, onClose, children }) {
  const dialogRef = useRef(null);

  const clickOutsideCheck = (e) => {
    if (dialogRef.current && !dialogRef.current.contains(e.target)) {
      onClose();
    }
  };

  const handlers = useSwipeable({
    onSwipedLeft: onClose,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  return (
    <div
      id="modalCallback"
      className={`
    ${styles.modal}
    ${show ? styles.in : ''}
     
      `}
      onClick={clickOutsideCheck}
      {...handlers}
    >
      <div
        className={`
        ${styles.modalDialog}
        ${styles.middle}
      `}
        ref={dialogRef}
      >
        {/* <div className={styles.modalContent}> */}
        {/* <div className={styles.modalBody}> */}
        {children}
        {/* </div> */}
        {/* </div> */}
      </div>
    </div>
  );
}
