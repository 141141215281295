import React, { useState, useCallback, useEffect, useRef } from 'react';
import { ReactComponent as MenuBtnComp } from './menu.svg';
import Drawer from './drawer/index';
import { Experience, Education } from './experience/index';
import Skills from './skills/index';
import Contacts from './contacts/index';
import $ from 'jquery';
import { useScrollPosition } from './hooks/useScrollPosition';
import { useWindowSize } from './hooks/useWindowSize';

import './App.css';
import './animate.css';

const about = `
I make web-applications with TypeScript, JavaScript, React. 
I realize complex business logic and adoptive, responsive design `;

function App() {
  const size = useWindowSize();
  const desktop = size && size.width > 1023;
  const [leftMenuVisible, setleftMenuVisible] = useState(false);

  const [fixedMenuBtnVisible, setFixedMenuBtnVisible] = useState(false);
  //const lastYoffset = useRef(0);
  const showLeftMenu = useCallback(() => setleftMenuVisible(true), [
    setleftMenuVisible,
  ]);
  const closeLeftMenu = useCallback(() => setleftMenuVisible(false), [
    setleftMenuVisible,
  ]);

  const animateToRef = useCallback(
    (e, ref) => {
      e.preventDefault();
      closeLeftMenu();
      if (!ref) return;
      $('html, body').animate(
        { scrollTop: ref?.current?.offsetTop || 0 },
        'slow'
      );
    },
    [closeLeftMenu]
  );

  const refAbout = useRef(null);
  const refExperience = useRef(null);
  const refSkills = useRef(null);
  const refEducation = useRef(null);
  const refContacts = useRef(null);

  //let throttleTimeout = null;

  // const [isLoaderVisible, setIsLoaderVisible] = useState(true);

  const hideLoader = () => {
    // setTimeout(() => {
    //   document.getElementById('pageLoader').classList.add('loaderHide');
    // }, 1500);

    document.getElementById('pageLoader').classList.add('loaderHide');
  };

  useScrollPosition(
    ({ prevPos, currPos }) => {
      // console.log(prevPos.y, currPos.y);
      if (desktop) setFixedMenuBtnVisible(false);
      else if (currPos.y < prevPos.y) {
        // console.log('set unvisible');
        setFixedMenuBtnVisible(false);
      } else if (currPos.y > prevPos.y && currPos.y < -300) {
        // console.log('set visible');
        setFixedMenuBtnVisible(true);
      } else if (currPos.y > -300) {
        // console.log('set unvisible top');
        setFixedMenuBtnVisible(false);
      }
    },
    [setFixedMenuBtnVisible, size],
    1
  );

  useEffect(() => {
    // setTimeout(() => {
    //   setIsLoaderVisible(false);
    // }, 500);
    hideLoader();
  }, []);

  const MenuList = useCallback(
    () => (
      <>
        <li>
          <a href="#about" onClick={(e) => animateToRef(e, refAbout)}>
            About
          </a>
          {/* if (window.$) window.$('html, body').animate({ scrollTop: step1Ref?.current?.offsetTop || 0 }, 'slow'); */}
        </li>
        <li>
          <a href="#experience" onClick={(e) => animateToRef(e, refExperience)}>
            Experience
          </a>
        </li>
        <li>
          <a href="#skills" onClick={(e) => animateToRef(e, refSkills)}>
            Skills
          </a>
        </li>
        <li>
          <a href="#education" onClick={(e) => animateToRef(e, refEducation)}>
            Education
          </a>
        </li>
        <li>
          <a href="#contacts" onClick={(e) => animateToRef(e, refContacts)}>
            Contacts
          </a>
        </li>
      </>
    ),
    [animateToRef]
  );

  //console.count('render');

  return (
    <>
      {/* {isLoaderVisible && <PageLoader />} */}
      <MenuBtnComp
        className={`fixedMenuBtn ${fixedMenuBtnVisible ? '' : 'invisible'}`}
        fill="#fff"
        onClick={showLeftMenu}
      />
      <header>
        <nav>
          <ul className="topMenu">
            <MenuList />
          </ul>

          {/* <img src={menuBtn} className="menuBtn"/>
     <svg >
      {menuBtn}
     </svg> */}
          <MenuBtnComp
            className="menuButton"
            fill="#fff"
            onClick={showLeftMenu}
          />
          <div className="sideMenu">
            <Drawer
              className="sidNav"
              show={leftMenuVisible}
              onClose={closeLeftMenu}
            >
              <div className="navHeader">
                {/* <img src={foto} className="navProfilePhoto" alt="face foto" /> */}
                <div className="navProfilePhoto"></div>
                <h6 className="sideMenuTitle">Evgenii Aladov</h6>
              </div>

              <div className="navLinks">
                <ul>
                  <MenuList onClose={closeLeftMenu} />
                </ul>
              </div>
            </Drawer>
          </div>
        </nav>
        <div className="intro" ref={refAbout}>
          <div className="animated fadeInDown">
            <h1> Evgenii Aladov</h1>
            <h4> Frontend developer</h4>
          </div>
        </div>

        <div className="introCircle animated zoomIn">
          <div className="introFoto" />
        </div>
      </header>
      <section className="about">
        <div className="card animated fadeInUp">
          <div className="social"></div>

          <div className="aboutText">{about}</div>

          <div className="buttons">
            <a
              href="https://docs.google.com/document/d/1A2lRybqCcti_eVKNQ_3elJ-O_yPdX3OoZ2O0bPuq16o/edit?usp=sharing"
              className="button-about"
              // download="aladov-eugeny.pdf"
            >
              {' '}
              Download CV
            </a>
            <button
              className="button-about"
              onClick={(e) => animateToRef(e, refContacts)}
            >
              {' '}
              Contact me
            </button>
          </div>
        </div>
      </section>
      <Experience ref={refExperience} />
      <Skills ref={refSkills} />
      <Education ref={refEducation} />
      <Contacts ref={refContacts} />
      <p className="rights">© 2023 Evgenii Aladov</p>
    </>
  );
}

export default App;
